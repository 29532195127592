import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Now`}</h1>
    <p>{`This is `}<a parentName="p" {...{
        "href": "https://nownownow.com/about"
      }}>{`a now page`}</a>{`.`}</p>
    <h2>{`Writing Code and Managing a Team`}</h2>
    <p>{`I currently work at `}<a parentName="p" {...{
        "href": "https://shopify.com"
      }}>{`Shopify`}</a>{`. I’m a development manager which means I manage a team, write code, and work with product, design, and engineering to deliver a compelling product.`}</p>
    <h2>{`Personal Projects`}</h2>
    <p>{`I write and maintain a number of personal programming projects to scratch various itches I have:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/wesbaker/github-pinboard-star-sync"
        }}>{`Syncing GitHub Stars to Pinboard`}</a></li>
      <li parentName="ul">{`Sending board game plays to `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/nemeslack"
        }}>{`Slack`}</a>{` and `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/nemecord"
        }}>{`Discord`}</a></li>
      <li parentName="ul">{`Running two Twitter Bots for `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/enworld-twitterbot"
        }}>{`ENWorld.org`}</a>{` and `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/tenkarstavern-twitterbot"
        }}>{`Tenkar’s Tavern`}</a>{` (two RPG news sites)`}</li>
      <li parentName="ul">{`And `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/alfred-goodreads-workflow"
        }}>{`a`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/alfred-stardewvalleywiki"
        }}>{`whole`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/alfred-kickstarter-workflow"
        }}>{`bunch`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/alfred-boardgamegeek"
        }}>{`of`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/alfred-dndbeyond-workflow"
        }}>{`Alfred`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/wesbaker/alfred-drivethrurpg"
        }}>{`workflows`}</a></li>
    </ul>
    <h2>{`3D Printing`}</h2>
    <p>{`This is my newest hobby, and therefore, the one I’m most into at the moment. I bought a `}<a parentName="p" {...{
        "href": "https://www.prusa3d.com/original-prusa-i3-mk3/"
      }}>{`Prusa i3 MK3S`}</a>{`, `}<a parentName="p" {...{
        "href": "https://epax3d.com/products/epax-printer"
      }}>{`an EPAX X1`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://phrozen3d.com/products/sonic-mini-4k-resin-3d-printer-phrozen"
      }}>{`a Sonic Mini 4K`}</a>{` and unless they’re broken, they’re probably printing something.`}</p>
    <h2>{`Roleplaying Games`}</h2>
    <p>{`Before 3D printing, roleplaying were my newest hobby and I still do a fair amount of reading and playing, but I've started to decrease the number of games I run and play in to a reasonable number. I still very much enjoy it, but I'm more realistic about how much I can actually play these days. I always want more though because there's so much out there.`}</p>
    <h2>{`Board Games`}</h2>
    <p>{`Before all of the above, there were board games and I still play them, but I've reached the point in the hobby and my life where I'm generally playing (and buying) fewer games. I used to buy nearly 100 games a year (and resell a bunch of those) and now I generally buy less than 10 in a year, which is still a lot. Additionally, members of my game group have moved away and kids can make playing games just a little bit harder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      